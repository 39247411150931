//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppButton from '@/components/AppButton.vue';

export default {
  name: 'Privacy',
  components: { AppButton },
  data() {
    return {
      isShowingCookieMonster: false,
      isShowingPreferences: false,
      cookies: {
        ga4: true,
      },
    };
  },
  mounted() {
    const cookie = this.getCookie('consent');
    if (cookie && cookie === 'allow') {
      this.isShowingCookieMonster = false;
    } else {
      this.isShowingCookieMonster = true;
    }
  },
  methods: {
    submitHandler() {
      this.isShowingCookieMonster = false;
      const expiration = new Date();

      const val = this.cookies.ga4 ? 'allow' : 'deny';

      expiration.setTime(expiration.getTime() + 2 * 365 * 24 * 60 * 60 * 1000);
      document.cookie = `consent=${val};expires=${expiration.toUTCString()};path=/`;

      if (this.$gtm && this.cookies.ga4) {
        this.$gtm.push({
          event: 'cookie-submit',
        });
      }
    },
    denyHandler() {
      this.isShowingCookieMonster = false;
    },
    getCookie(name) {
      const cookie = name + '=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(cookie) === 0) {
          return c.substring(cookie.length, c.length);
        }
      }
      return '';
    },
  },
};
