//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  Hero,
  Expertise,
  Services,
  // Audience,
  Breaker,
  WorkWithUs,
} from '@/components/sections';
import ContactDialog from '@/components/contact/ContactDialog';
import Privacy from '@/components/Privacy';

export default {
  components: {
    Hero,
    Expertise,
    Services,
    // Audience,
    Breaker,
    WorkWithUs,
    ContactDialog,
    Privacy,
  },
  data() {
    return {
      isShowingFloatingButton: true,
    };
  },
  methods: {
    openContact() {
      if (this.$gtm) {
        this.$gtm.push({
          event: 'open-contact',
        });
      }
    },
    toggleFloatingButton(footerIsVisible) {
      this.isShowingFloatingButton = !footerIsVisible;
    },
  },
};
